import React, { useEffect, useLayoutEffect, useContext, useState } from "react"
import { useRouter } from "next/router"

import { Navbar, Footer, Loader } from "components/anti"
import { LoadingContext } from "context/loading-context"
import { Toaster } from "react-hot-toast"
import Cookies from "js-cookie"
import LoaderFuru from "./global/loader-furu"
import { AnimatePresence, motion } from "framer-motion"
import { fadeInOut2 } from "src/variants/variants"
import reactUseCookie, { getCookie } from "react-use-cookie"
import { useCookie } from "@reactivers/use-cookie"

const Layout = ({ children }) => {
  const router = useRouter()

  const navigate = (to) => {
    router.push(to)
  }

  const [showMobileMenu, setShowMobileMenu] = useState(false)

  // Loader Context
  // allows loader only shown when directly visited via URL
  // const isShowLoader = Cookies.get("show_loader")
  // const isShowLoader = getCookie("show_loader")
  const { getItem } = useCookie("show_loader")
  const isShowLoader = getItem()
  // const [isShowLoader, setIsShowLoader] = reactUseCookie("show_loader")
  // const { initialLoading } = useContext(LoadingContext)

  // Mobile viewport height workaround
  // prevent quirky behaviour on mobile when cover is set to 100vh
  useEffect(() => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }, [])

  // Transition link
  // for smooth transition effect
  useEffect(() => {
    const wrapper = document.querySelector("main")
    wrapper.classList.remove("fadeOut")
    wrapper.classList.add("animated", "fadeIn")
  }, [router])
  useEffect(() => {
    const wrapper = document.querySelector("main")
    const anchor = document.querySelectorAll("a")
    anchor.forEach((item) => {
      const navigation = (e) => {
        const redirect = item.getAttribute("href")
        const currentLoc = window.location.pathname
        e.preventDefault()
        if (currentLoc !== redirect) {
          wrapper.classList.remove("fadeIn")
          wrapper.classList.add("fadeOut")
          setTimeout(() => {
            navigate(redirect)
          }, 250)
        }
      }
      if (item.getAttribute("href") && !item.getAttribute("target")) {
        item.addEventListener("click", navigation)
      }
    })
    return () => {
      anchor.forEach((item) => {
        const navigation = (e) => {
          const redirect = item.getAttribute("href")
          const currentLoc = window.location.pathname
          e.preventDefault()
          if (currentLoc !== redirect) {
            wrapper.classList.remove("fadeIn")
            wrapper.classList.add("fadeOut")
            setTimeout(() => {
              navigate(redirect)
            }, 250)
          }
        }
        if (item.getAttribute("href") && !item.getAttribute("target")) {
          item.removeEventListener("click", navigation)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    const body = document.body
    const html = document.querySelector("html")
    body.classList.remove("no-scroll")
    html.classList.remove("no-scroll")
  }, [])
  return (
    <>
      <Toaster position="top-right" containerClassName="toaster-notification" />
      {/* <Loader variant="lottie" theme="dark" effect="fadeOut" /> */}
      <LoaderFuru />
      <Navbar handleOpenMobileMenu={() => setShowMobileMenu(!showMobileMenu)} />
      {/* <div
        className={`modal-mobile-menu ${showMobileMenu ? "fadeInUp" : ""}`}
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          background: "red",
          width: "100%",
          height: "100%",
          zIndex: "1",
          overflowY: "hidden",
        }}
      ></div> */}
      <main>
        <div id="mobile-menu-portal"></div>
        {/* {!isShowLoader ? (
          <div className="h-vh-100 w-vw-100" />
        ) : (
          <>{children}</>
        )} */}
        <AnimatePresence initial={false} exitBeforeEnter>
          {isShowLoader && (
            <motion.div
              variants={fadeInOut2}
              key={"key-layout"}
              initial="initial"
              animate="animate"
              exit="exit"
              className="layout"
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
        <Footer />
      </main>
    </>
  )
}

export default Layout
