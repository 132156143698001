import { Button, Cover, Image, Link } from "components/anti"
import bg from "assets/img/common/bg_home.png"
import imgHomeCover from "assets/img/common/img_home-cover.png"
import token from "assets/img/common/img_token.png"
import Lottie from "react-lottie"

import char from "assets/lottie/token-shop-character.json"

export const HomeCover = ({ statistic }) => {
  return (
    <Cover
      img={bg}
      contentMaxWidth="mw-100"
      className="cover-home"
      imgHeight="h-400px h-md-600px h-xxxl-800px"
    >
      <div className="row mt-md-5">
        <div className="col-lg-7 col-xl-6 col-text">
          <h1 className="cover-title">
            Stake your Furus to Gather{" "}
            {<Image src={token} className="token" alt="token" />} Furu Token and{" "}
            {/* <br /> */}
            Earn Rewards
          </h1>
          <p className="cover-text">
            <b>{statistic?.stake_in}</b> furus are playing on the playground!
          </p>
          <Button link="/playarea" className="w-200px anim fadeInUp d4">
            STAKE NOW
          </Button>
        </div>
        <div className="col-lg-5 col-xl-6 col-char">
          <Image
            src={imgHomeCover}
            className="img-fluid img-home-cover anim fadeInUp d5"
            alt="img-home-cover"
          />
          <div className="char-wrapper anim fadeInUp d6">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: char,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              ariaRole="lottie"
              isClickToPauseDisabled
            />
          </div>
        </div>
      </div>
    </Cover>
  )
}
