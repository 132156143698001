import Cookies from "js-cookie"
import Lottie from "react-lottie"
import { useEffect, useState } from "react"
import { useCookie } from "@reactivers/use-cookie"
import { AnimatePresence, motion } from "framer-motion"

import { Button, Image } from "components/anti"
import { fadeIn, fadeIn2, fadeInOut2 } from "src/variants/variants"

import furu from "public/img/common/img-loader.png"
import loadingJSON from "assets/lottie/loading-general.json"

const LoaderFuru = () => {
  const { getItem, setItem } = useCookie("show_loader")
  const isShowLoader = getItem()

  const [animate, setAnimate] = useState(1)
  const [hideButton, setHideButton] = useState(false)
  const onEnter = () => {
    setHideButton(true)
    setTimeout(() => {
      setAnimate(2)
    }, 1000)
  }
  useEffect(() => {
    if (animate === 2) {
      setTimeout(() => {
        Cookies.set("show_loader", true)
        setItem({ value: true, expireDays: 7 })
      }, 5000)
    }
  }, [animate, setItem])
  useEffect(() => {
    if (animate === 1 && isShowLoader) {
      setAnimate(2)
    }
  }, [isShowLoader, animate])
  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      {!isShowLoader && (
        <motion.div
          variants={fadeInOut2}
          key={"key-loader-furu"}
          initial="initial"
          animate="animate"
          exit="exit"
          className="loader-furu"
        >
          <AnimatePresence exitBeforeEnter>
            {animate === 1 && (
              <motion.div
                variants={fadeIn}
                key={"key-loader-furu__starter"}
                initial="initial"
                animate="animate"
                exit="exit"
                className="loader-furu__starter"
              >
                <Image
                  src={furu}
                  className="img-furu img-fluid"
                  alt="img-furu"
                />
                <Button
                  className={`mt-3 ${hideButton ? "fadeOutUp" : ""}`}
                  onClick={() => onEnter()}
                >
                  Enter Playground
                </Button>
              </motion.div>
            )}
            {animate === 2 && (
              <motion.div
                variants={fadeIn2}
                key={"key-loader-furu__enter"}
                initial="initial"
                animate="animate"
                exit="exit"
                className="loader-furu__enter"
              >
                <Lottie
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: loadingJSON,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  ariaRole="lottie"
                  isClickToPauseDisabled
                />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default LoaderFuru
