import axios from "axios"
import { MAIN_URL } from "lib"

export const getByOptions = async (opts) => {
  let { type, show } = opts
  let url = "/programmes"

  if (type || show) url += "?"

  if (show) url += `show=${show}&`
  if (type) url += `type=${type}`
  try {
    let { data } = await axios.get(MAIN_URL(url))
    if (data?.success)
      return { data: data?.data?.length > 0 ? data?.data : [], errors: null }
    else return { errors: "Oops something wrong" }
  } catch (error) {
    return { errors: error }
  }
}
