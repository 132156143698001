import axios from "axios"
import { Image, Link } from "components/anti"
import { useScrollAnim } from "components/hooks/hooks"
import Layout from "components/layout"
import { HomeCover } from "components/pages/home/home-cover"
import { HomeHighlight } from "components/pages/home/home-highlight"
import SEO from "components/seo"
import { MAIN_URL } from "lib"

import furuRun from "assets/video/furu-run.webp"

const HomePage = ({ data, statistic }) => {
  const [trigger, anim] = useScrollAnim()
  return (
    <Layout>
      <div ref={trigger}>
        <Link
          className={`furu-run-cta ${anim(10, "slideInRight")}`}
          to="/furu-run"
        >
          <Image src={furuRun} className="img" alt="furu-run" />
        </Link>
      </div>
      <SEO title="Home" bodyClassName="bd-home" />
      <HomeCover statistic={statistic} />
      <HomeHighlight data1={data} />
    </Layout>
  )
}

export default HomePage

export const getServerSideProps = async (ctx) => {
  try {
    let { data } = await axios.get(MAIN_URL(`/programmes?type=ongoing`))
    let statistic = await axios.get(MAIN_URL("/stake/statistic"))
    if (data?.success) {
      return { props: { data: data?.data, statistic: statistic?.data } }
    }
    return { notFound: true }
  } catch (error) {
    return { notFound: true }
  }
}
