import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const ProductLoading = ({ show, id, wrapperClassName = "" }) => {
  return (
    <div className="row">
      {Array(8)
        .fill()
        .map((_, i) => (
          <div key={`${i}-skeleton-${id}`} className={wrapperClassName || "col-md-6 col-lg-3 py-3"}>
            <Skeleton height={280} />
            <Skeleton height={10} width={"40%"} className="mt-3" />
            <Skeleton height={10} width={"100%"} className="" />
            <Skeleton height={10} width={"15%"} className="" />
          </div>
        ))}
    </div>
  )
}

export default ProductLoading
