import React, { useEffect } from "react"
import { Button, Card, Image, Link, Select } from "components/anti"
import toast from "react-hot-toast"
import Notification from "components/global/notification"
import { ProductDetailLink } from "lib"
import ProductLoading from "components/global/product-loading"
import { getByOptions } from "./utils"
import { ShowToast } from "lib/toast"
import { useScrollAnim } from "components/hooks/hooks"

import goji from "assets/video/goji.gif"

export const HomeHighlight = ({ data1 }) => {
  const [trigger, anim] = useScrollAnim()
  //States ************************************************************************** //
  const [state, setState] = React.useState({
    loadingSection: false,
    product: data1,
    typeValue: { value: "ongoing", label: "Ongoing" },
    showValue: { value: null, label: "All" },
  })
  //States ************************************************************************** //

  //Functions *********************************************************************** //
  const handleSelect = async (value, type) => {
    setState((prev) => ({ ...prev, [type]: value, loadingSection: true }))
    let options = {}
    //prettier-ignore
    if (type === "typeValue") options = { ...options, type: value.value || null, show: state.showValue?.value }
    //prettier-ignore
    if (type === "showValue") options = { ...options, show: value.value || null, type: state.typeValue?.value }

    //prettier-ignore
    await fetchData(options)
  }
  const fetchData = async (options) => {
    let { data, errors } = await getByOptions({ ...options })
    if (data) {
      setTimeout(() => {
        setState((prev) => ({
          ...prev,
          loadingSection: false,
          product: data.length > 0 ? data : null,
        }))
      }, 2000)
    } else {
      setState((prev) => ({ ...prev, loadingSection: false }))
    }
  }
  //Functions *********************************************************************** //

  //React Operations *********************************************************************** //
  useEffect(() => {
    // fetchData({ type: "ongoing", show: "most-popular" })
  }, [])
  //React Operations *********************************************************************** //
  // console.log("state", state?.product)
  return (
    <section
      className="sc-home-highlight container pt-md-5 pt-3 pb-4 mt-md-4"
      ref={trigger}
    >
      <div className="row">
        <div className="col-lg-6">
          <h1 className={`title fadeInUp d1`}>Browse Rewards</h1>
        </div>
        <div className="col-lg-6">
          <div className="row">
            <div className="col-6">
              <Select
                // variant="underline"
                // floatingLabel={true}
                name="Type"
                label="Type"
                onChange={(_, value) => handleSelect(value, "typeValue")}
                // className={anim(2)}
                className="fadeInUp d2"
                options={[
                  { value: null, label: "All" },
                  { value: "ongoing", label: "Ongoing" },
                  { value: "past", label: "Past" },
                ]}
                value={state.typeValue}
                isDrawerOnMobile
              />
            </div>
            <div className="col-6">
              <Select
                // variant="underline"
                // floatingLabel={true}
                name="Show"
                label="Show"
                onChange={(_, value) => handleSelect(value, "showValue")}
                value={state.showValue}
                // className={anim(3)}
                className="fadeInUp d3"
                options={[
                  { value: null, label: "All" },
                  { value: "end", label: "Ending soon" },
                  { value: "high", label: "Highest token price" },
                  { value: "low", label: "Lowest token price" },
                ]}
                isDrawerOnMobile
              />
            </div>
          </div>
        </div>
      </div>
      {state.loadingSection ? (
        <ProductLoading id="home" />
      ) : (
        <div className="row pt-3">
          {state?.product?.length ? (
            <>
              {state.product?.map((item, i) => {
                return (
                  <div key={i} className="col-md-4 col-lg-4 col-xl-3 pt-2">
                    <Card
                      type={item.type}
                      date={item.end_date?.format}
                      isEnded={item.end_date?.is_end}
                      rawDate={item.end_date?.raw}
                      variant="boxless"
                      title={item.name}
                      img={item.image}
                      imgRatio="r-1-1"
                      label={item.type}
                      soldout={
                        item?.type === "sale"
                          ? item.stock.available === 0
                            ? true
                            : false
                          : false
                      }
                      cardBgClassName=""
                      link={ProductDetailLink(item)}
                      className={`card-token-shop fadeInUp d${i + 4}`}
                      text={
                        <p>
                          {item.type === "auction" ? "Buy Now at " : ""}
                          <span className="with-token">{item.price}</span>
                        </p>
                      }
                    />
                  </div>
                )
              })}
            </>
          ) : (
            <div className={`empty-wrapper fadeInUp d4`}>
              <div className="row">
                <div className="col-md-6 col-img">
                  <Image
                    src={goji}
                    className={`img-fluid fadeInUp d5`}
                    alt="goji"
                  />
                </div>
                <div className="col-lg-6 col-text">
                  <p className={`textfadeInUp d6`}>No Rewards Found</p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </section>
  )
}

// const data = [
//   {
//     img: card1,
//     title: "NFT Karafuru 3D Egao #001",
//     type: "raffle",
//     price: 10,
//     date: "1D : 11H : 59M : 59S",
//   },
//   {
//     img: card3,
//     title: "WL NFT Karafuru x Sidekast",
//     type: "auction",
//     price: 10,
//     date: "10 JUNE 2022",
//   },
//   {
//     img: card5,
//     title: "WL Karafuru x Mindblowon",
//     type: "raffle",
//     price: 10,
//     date: "1D : 11H : 59M : 59S",
//   },
//   {
//     img: card8,
//     title: "WL NFT Karafuru x Sidekast",
//     type: "sale",
//     price: 10,
//     date: "10 JUNE 2022",
//   },
//   {
//     img: card3,
//     title: "WL NFT Karafuru x Sidekast",
//     type: "auction",
//     price: 10,
//     date: "10 JUNE 2022",
//   },
//   {
//     img: card2,
//     title: "NFT Karafuru #2301",
//     type: "raffle",
//     price: 10,
//     date: "1D : 11H : 59M : 59S",
//   },
//   {
//     img: card7,
//     title: "WL NFT Karafuru x Sidekast",
//     type: "sale",
//     price: 10,
//     date: "10 JUNE 2022",
//   },
//   {
//     img: card5,
//     title: "WL Karafuru x Mindblowon",
//     type: "raffle",
//     price: 10,
//     date: "1D : 11H : 59M : 59S",
//   },
// ]
